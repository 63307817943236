import React, { useState, useEffect } from "react";
import axios from "axios";
import useUser from "../../hooks/user";
import { __API_URL__ } from "../../SERVER_URL";
import { useParams } from "react-router";
import AddNewModal from "../Modals/AddNewTrial";
import TrialParticipantIdAccordion from '../TrialAccordions/TrialParticipantIdAccordion'
import DrugAssignmentAccordion from '../TrialAccordions/DrugAssignmentAccordion'
import DrugIdRangeAccordion from '../TrialAccordions/DrugIdRangeAccordion'
import Investigator_SiteId_Accordion from '../TrialAccordions/Investigator_SiteId_Accordion'
import TrialReviewersAccordion from '../TrialAccordions/TrialReviewersAccordion'
import { toast, ToastContainer } from "react-toastify";
import MaximumPhotoTypesAccordion from "../TrialAccordions/MaximumPhotoTypesAccordion";

const Basic_Info = ({ numberOfVisits, numberOfVisitsSetter, setMessage, setTypeOfMessage, isEvent, setisEvent, eventList, setEventList, setSelectedTrialType }) => {
    const token = localStorage.getItem("token");
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role;
    const user = useUser();
    const [loader, setLoader] = useState(false);
    const { _id_ } = useParams();
    const [trialDetails, setTrialDetails] = useState({})
    const [allInvestigators, setAllInvestigators] = useState({})
    const [allReviewers, setAllReviewers] = useState({})
    const [minVisits, setMinVisits] = useState(0)
    const [localNumberOfVisits, setLocalNumberOfVisits] = useState(0)
    const [hasChanged, setHasChanged] = React.useState(false)
    const [consentFormBool, setConsentFormBool] = React.useState(false)
    const [surveyFormBool, setSurveyFormBool] = React.useState(false)
    const [surveyFormOption, setSurveyFormOption] = React.useState("list")
    const [selectedVisit, setSelectedVisit] = React.useState('survey_form')
    const [modalShow, setModalShow] = React.useState(false);
    const [allSurveys, setAllSurveys] = React.useState([])
    const [modalTrialTypeShow, setModalTrialTypeShow] = useState(false);
    const [trialType, setTrialType] = useState([]);
    const [changeTrialSetting, setChangeTrialSetting] = useState({'participant_ids': '', 'site_ids': ''});
    const [changeSetting, setChangeSetting] = useState(false);
    const [siteIdManual, setSiteIdManual] = useState(false);
    const [investigatorChanged, setInvestigatorChanged] = useState(false);
    const [trialSetting, setTrialSetting] = useState('Participant Id');
    const [infoSettingChanged, setInfoSettingChanged] = useState(false);
    const [reviewerChanged, setReviewerChanged] = useState(false);
    const [ParticipantIdChanged, setParticipantIdChanged] = useState(false);
    const [DrugChanged, setDrugChanged] = useState(false);
    const [DrugRangeChanged, setDrugRangeChanged] = useState(false);
    const [maximumPhototypes, setMaximumPhototypes] = useState(0);
    const [errorMsg, seterrorMsg] = useState('');
    const [title, setTitle] = useState('');
 
    const onInvestigatorChange = (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
                if (actionMeta.removedValue.isFixed) {
                    setMessage("Cannot remove the Investigator");
                    setTypeOfMessage("success");
                    setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "2rem";
                    }, 100);
                    setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "-99rem";
                    }, 3000);
                    return newValue;
                }
                else {
                    setInvestigatorChanged(true)
                    setChangeSetting(true)
                    newValue = trialDetails.investigators.filter((v) => v.value != actionMeta.removedValue.value);
                    setTrialDetails((trial) => {
                        return {
                            ...trial,
                            investigators: newValue
                        }
                    });
                }
                break;
            case 'pop-value':
                break;
            case 'clear':
                setInvestigatorChanged(true)
                setChangeSetting(true)
                newValue = trialDetails.investigators.filter((v) => v.isFixed);
                setTrialDetails((trial) => {
                    return {
                        ...trial,
                        investigators: newValue
                    }
                });
                break;
            case 'select-option':
                setInvestigatorChanged(true)
                setChangeSetting(true)
                setTrialDetails((trial) => {
                    return {
                        ...trial,
                        investigators: newValue
                    }
                });
                break;
        }
        // AllInvestigatorsData()
    }

    const onReviewerChange = (newValue, actionMeta) => {
        switch (actionMeta.action) {
            case 'remove-value':
                if (actionMeta.removedValue.isFixed) {
                    setMessage("Cannot remove the Reviewer");
                    setTypeOfMessage("success");
                    setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "2rem";
                    }, 100);
                    setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "-99rem";
                    }, 3000);
                    return newValue;
                }
                else {
                    setReviewerChanged(true)
                    newValue = trialDetails.reviewers.filter((v) => v.value != actionMeta.removedValue.value);
                    setTrialDetails((trial) => {
                        return {
                            ...trial,
                            reviewers: newValue
                        }
                    });
                }
                break;
            case 'pop-value':
                break;
            case 'clear':
                setReviewerChanged(true)
                newValue = trialDetails.reviewers.filter((v) => v.isFixed);
                setTrialDetails((trial) => {
                    return {
                        ...trial,
                        reviewers: newValue
                    }
                });
                break;
            case 'select-option':
                setReviewerChanged(true)
                setTrialDetails((trial) => {
                    return {
                        ...trial,
                        reviewers: newValue
                    }
                });
                break;
        }
        // AllReviewerData()
    }

    const handleFieldChange = (e) => {
        setHasChanged(true)
        const { name, value } = e.target;
        setTrialDetails((trial) => {
            return {
                ...trial,
                [name]: value
            }
        })
    }

    const handleVisitFieldChange = (e) => {
        setHasChanged(true)
        const { name, value } = e.target;

        if (name === 'drug_video') {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    [name]: !visit.drug_video
                }
            })
        } else if (name === "title" || name === "content") {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    consent_form: {
                        ...visit.consent_form,
                        [name]: value
                    }
                }
            })
        } else if (name === "contnt") {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    visit_instructions: {
                        ...visit.visit_instructions,
                        [name]: value,


                    }
                }
            })

        } else if (name === "consent_list_id") {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    consent_form: {
                        ...visit.consent_form,
                        id: value
                    }
                }
            })
        }
        else if (name === "survey_form") {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    survey_form: { id: value }
                }
            })
        }
        else if (["Lesion 1", "Lesion 2", "Lesion 3"].includes(name)) {
            setSelectedVisit((visit) => {
                let current_extras = visit.extra_photos
                const index = current_extras.indexOf(name);
                if (index > -1) current_extras.splice(index, 1);
                else current_extras.push(name);
                return {
                    ...visit,
                    extra_photos: current_extras
                }
            })
        }
        else {
            setSelectedVisit((visit) => {
                return {
                    ...visit,
                    [name]: value
                }
            })
        }
    }

    const handleBoolOperation = (e) => {
        const targetName = e.target.name
        if (targetName === 'surveyBool') {
            if (surveyFormBool) {
                setSelectedVisit((visit) => {
                    return {
                        ...visit,
                        survey_form: ""
                    }
                })
            }
            setSurveyFormBool((prev) => !prev)

        } else if (targetName === 'consentBool') {
            if (consentFormBool) {
                setSelectedVisit((visit) => {
                    return {
                        ...visit,
                        consent_form: { id: "" }
                    }
                })
            }
            setConsentFormBool((prev) => !prev)
        }
    }

    const handleBasicInfoUpdate = () => {
        const updatedTrialDetails = { ...trialDetails, total_visits: localNumberOfVisits, special_visits: isEvent};

        axios
            .patch(`${__API_URL__}/api/photos/edit_trial/${trialDetails.id}/`, updatedTrialDetails, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                res = res.data;
                let investigator_list = [];
                res.investigator_details.map((investigator) => {
                    investigator_list.push({ value: investigator.email, label: investigator.email, isFixed: true, site_number: investigator.site_number, minimum_drug: investigator.minimum_drug, max_patient:investigator.max_patient})
                });
                let reviewer_list = [];
                res.reviewer_details.map((reviewer) => {
                    reviewer_list.push({ value: reviewer.email, label: reviewer.email, isFixed: true, site_number: reviewer.site_number })
                });
                res = {
                    ...res,
                    investigators: investigator_list,
                    reviewers: reviewer_list,
                };

                setSelectedTrialType(res.trial_type?.title);
                setTrialDetails({ ...res, trial_type: res.trial_type?.id, trial_type_title: res.trial_type?.title });
                setChangeSetting(false);
                setHasChanged(false);
                setInfoSettingChanged(false);
                setInvestigatorChanged(false);
                setReviewerChanged(false);
                setParticipantIdChanged(false);
                setDrugChanged(false);
                setDrugRangeChanged(false);
                setMessage("Updated Successfully");
                setTypeOfMessage("success");
                numberOfVisitsSetter(localNumberOfVisits);

                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                setMessage(err?.response?.data?.message);
                setTypeOfMessage("error");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
    }

    useEffect(() => {
        setLocalNumberOfVisits(numberOfVisits);
    }, [numberOfVisits])

    const trialDetailApi = () => {
        axios
            .get(`${__API_URL__}/api/photos/get_trial/${_id_}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                res = res.data
                let investigator_list = []
                res.investigator_details.map((investigator) => {
                    investigator_list.push({ value: investigator.email, label: investigator.email, isFixed: true, site_number: investigator.site_number, minimum_drug: investigator.minimum_drug , max_patient:investigator.max_patient})
                })
                let reviewer_list = []
                res.reviewer_details.map((reviewer) => {
                    reviewer_list.push({ value: reviewer.email, label: reviewer.email, isFixed: true, site_number: reviewer.site_number })
                })
                res = {
                    ...res,
                    investigators: investigator_list,
                    reviewers: reviewer_list,
                }
                setTrialDetails({ ...res, trial_type: res.trial_type?.id, trial_type_title: res.trial_type?.title });
                setLocalNumberOfVisits(res.total_visits)
                numberOfVisitsSetter(res.total_visits)
            });
    }

    const AllInvestigatorsData = () => {
        axios
            .get(`${__API_URL__}/api/investigatorsList/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                let customData = []
                res.data.map((obj) => {
                    customData.push({
                        value: obj.email, label: obj.email
                    })
                })
                setAllInvestigators(customData);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
            });
    };

    const AllReviewerData = () => {
        axios
            .get(`${__API_URL__}/api/reviewerList/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {

                setAllReviewers(res.data);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
            });
    };

    const getTrialTypes = () => {
        axios
            .get(`${__API_URL__}/api/photos/get_trial_types/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                res = res.data
                setTrialType(res);
            });
    }

    const handleNumberOfVisits = () => {
        numberOfVisitsSetter(localNumberOfVisits);
    }

    useEffect(() => {
        trialDetailApi()
        AllInvestigatorsData()
        getTrialTypes()
        AllReviewerData()
    }, [])

    const handleScreeningEventClick = (isChecked) => {
        if (isChecked) {
            if (!eventList.includes(-3)) {
                setEventList(prevList => [...prevList, -3]);
            }
        } else {
            setEventList(prevList => prevList.filter(event => event !== -3));
        }
    };

    const handleUnscheduledEventClick = (isChecked) => {
        if (isChecked) {
            if (!eventList.includes(-2)) {
                setEventList(prevList => [...prevList, -2]);
            }
        } else {
            setEventList(prevList => prevList.filter(event => event !== -2));
        }
    };

    const handleAdverseEventClick = (isChecked) => {
        if (isChecked) {
            if (!eventList.includes(-1)) {
                setEventList(prevList => [...prevList, -1]);
            }
        } else {
            setEventList(prevList => prevList.filter(event => event !== -1));
        }
    };

    const addTrialTypeApi = () => {
        setModalTrialTypeShow(false)
        const Createdata = {
          'title': title,
        };
        axios
        .post(`${__API_URL__}/api/photos/add_trial_type/`, Createdata, {
            headers: {
            Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
        const newTrialType = res.data; 
        setTrialType(prevTrialTypes => [...prevTrialTypes, newTrialType]); 
        setTrialDetails(prevDetails => ({
            ...prevDetails,
            trial_type: newTrialType.id, 
        }));
        toast.success("Trial Type Added");
        setTitle('');
        }).catch((err) => {
            toast.error("Something Went Wrong!");
        });
    }

    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                </div>

                    <>
                        {
                            (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') && <>
                                <div className="col-md-6 text-end">
                                    <button type="submit" class="btn btn-primary btn-sm mx-5" 
                                    onClick={() => handleBasicInfoUpdate()}
                                    disabled={!hasChanged}
                                    >
                                        {loader ? (
                                            <i class="fa fa-spinner fa-spin"></i>
                                        ) : "Update Basic Info"
                                        }
                                    </button>
                                </div>
                            </>
                        }
                    </>

            </div>
            <div className="row" key={trialDetails.id} style={{ padding: '1em' }}>
                <div className="col-md-6">
                    <label>Custom Id</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleFieldChange(e)}
                        name="trial_custom_id"
                        value={trialDetails.trial_custom_id}
                    ></input>
                </div>
                <div className="col-md-6">
                    <label>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleFieldChange(e)}
                        name="name"
                        value={trialDetails.name}
                    ></input>
                </div>
                <div className="col-md-6">
                    <label>Status</label>
                    <select className="form-control"
                        onChange={(e) => handleFieldChange(e)}
                        name="status"
                        value={trialDetails.status}
                    >
                        <option value="" disabled>Select</option>
                        <option value="Active">Active</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="New">New</option>
                    </select>
                </div>

                {
                    (role !== "Investigator" && role !== "Inv_coordinator" && role !== "Super Admin" && role !== 'Reviewer') && <>
                        <div className="col-md-6">
                            <label>Number Of Visits</label>
                            <input
                                type="number"
                                min={minVisits}
                                className="form-control"
                                onChange={(e) => { setLocalNumberOfVisits(e.target.value); setHasChanged(true) }}
                                name="total_visits"
                                value={localNumberOfVisits}
                            ></input>
                        </div>
                    </>

                }
                <div className="col-md-6">
                    <label>Trial Type</label>
                    {
                        (role === "Investigator"  || role === 'Sub_investigator' || role === "Inv_coordinator") ? <>
                            <input className="form-control" value={trialDetails.trial_type_title} disabled={true} ></input>
                        </> : <>
                            {
                                <div className="row">
                                    <div className="col-md-9">
                                    <select className="form-control"
                                        onChange={handleFieldChange}
                                        name="trial_type"
                                        value={trialDetails?.trial_type}
                                        required
                                    >
                                        <option value="">Select</option>
                                        {trialType && trialType.length > 0 ? 
                                            trialType.map(type => (
                                                <option value={type.id} key={type.id}>{type.title}</option>
                                            )) : 
                                            <option value="">No trial type available</option>
                                        }
                                    </select>
                                    </div>
                                    {role === 'Reviewer' ? '' :
                                        <div className="col-md-3">
                                            <button
                                                class="btn btn-primary btn-sm"
                                                onClick={() => setModalTrialTypeShow(true)}
                                            >
                                                Add New
                                            </button>
                                        </div>
                                    }
                                    {modalTrialTypeShow && (
                                        <AddNewModal
                                            show={modalTrialTypeShow}
                                            onHide={() => setModalTrialTypeShow(false)}
                                            formSubmissionApi={addTrialTypeApi}
                                            modalShow={setModalTrialTypeShow}
                                            title={title}
                                            setTitle={setTitle}
                                        />
                                    )}
                                </div>
                            }
                        </>
                    }
                </div>

                {
                    ((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') && (role !== 'Inv_coordinator')) && <>
                        <div className="col-md-2  my-4">
                            <label> Hide patient photos </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={trialDetails.is_hide_image}
                                name='is_hide_image'
                                type="checkbox"
                                value={trialDetails.is_hide_image}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setTrialDetails((trialDetails) => ({
                                        ...trialDetails, is_hide_image: !trialDetails.is_hide_image
                                    })); setHasChanged(true)
                                }}
                            />
                        </div>
                    </>
                }

                {
                    ((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') || (role === 'Super Admin')) && <>
                        <div className="col-md-2  my-4">
                            <label> Adverse Event </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={isEvent.adverse_event}
                                name='adverse_event'
                                type="checkbox"
                                value={isEvent.adverse_event}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setisEvent((prevState) => ({
                                        ...prevState,
                                        adverse_event: !prevState.adverse_event
                                    }));
                                    setHasChanged(true);
                                    handleAdverseEventClick(!isEvent.adverse_event);
                                }}
                            />
                        </div>
                        <div className="col-md-2  my-4">
                            <label> Unscheduled Event </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={isEvent.unscheduled_event}
                                name='unscheduled_event'
                                type="checkbox"
                                value={isEvent.unscheduled_event}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setisEvent((prevState) => ({
                                        ...prevState,
                                        unscheduled_event: !prevState.unscheduled_event
                                    }));
                                    setHasChanged(true);
                                    handleUnscheduledEventClick(!isEvent.unscheduled_event);
                                }}

                            />
                        </div>
                        <div className="col-md-2  my-4">
                            <label> Screening Visit </label>
                            <input
                                style={{ marginLeft: "1em" }}
                                checked={isEvent.screening_visit}
                                name='screening_visit'
                                type="checkbox"
                                value={isEvent.screening_visit}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setisEvent((prevState) => ({
                                        ...prevState,
                                        screening_visit: !prevState.screening_visit
                                    }));
                                    setHasChanged(true);
                                    handleScreeningEventClick(!isEvent.screening_visit);
                                }}
                            />
                        </div>
                        </>
                }
                        <div className="col-md-2  my-4">
                            <label> Automatic email notifications </label>
                            <input
                                style={{ marginLeft: "1em" }}
                                checked={trialDetails.automatic_email_notification}
                                name='automatic_email_notification'
                                type="checkbox"
                                disabled={(role === 'Investigator' || role === 'Inv_coordinator') ? true: false}
                                value={trialDetails.automatic_email_notification}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setTrialDetails((trialDetails) => ({
                                        ...trialDetails, automatic_email_notification: !trialDetails.automatic_email_notification
                                    })); 
                                    setHasChanged(true);
                                }}
                            />
                        </div>
                        <div className="col-md-2  my-4">
                            <label> Drug Assignment </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={trialDetails.drug_assignment}
                                name='drug_assignment'
                                type="checkbox"
                                disabled={(role === 'Investigator' || role === 'Inv_coordinator')?true:false}
                                value={trialDetails.drug_assignment}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setTrialDetails((trialDetails) => ({
                                        ...trialDetails, drug_assignment: !trialDetails.drug_assignment
                                    })); setHasChanged(true)
                                }}
                            />
                        </div>
                        <div className="col-md-2  my-4">
                            <label> Maximum Photo Types </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={trialDetails.is_maximum_photo_types}
                                name='maximum_photo_types'
                                type="checkbox"
                                disabled={(role === 'Investigator' || role === 'Inv_coordinator') ? true : false}
                                value={trialDetails.is_maximum_photo_types}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setTrialDetails((trialDetails) => ({
                                        ...trialDetails, is_maximum_photo_types: !trialDetails.is_maximum_photo_types
                                    })); setHasChanged(true)
                                }}
                            />
                        </div>
                        {/* <div className="col-md-2  my-4">
                            <label> Blind Trial </label>
                            <input

                                style={{ marginLeft: "1em" }}
                                checked={trialDetails?.blind_trial}
                                name='blind_trial'
                                type="checkbox"
                                value={trialDetails?.blind_trial}
                                disabled={(role === 'Investigator' || role === 'Inv_coordinator')?true:false}
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    setTrialDetails((trialDetails) => ({
                                        ...trialDetails, blind_trial: !trialDetails.blind_trial
                                    })); setHasChanged(true)
                                }}

                            />
                        </div> */}
                        
                        {((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') || (role === 'Super Admin') && (role !== 'Inv_coordinator')) && <>

                            {trialDetails?.is_setting &&

                            <TrialParticipantIdAccordion
                                trialSetting={trialSetting}
                                trialDetails={trialDetails}
                                hasChanged={hasChanged}
                                setChangeTrialSetting={setChangeTrialSetting}
                                changeTrialSetting={changeTrialSetting}
                                setHasChanged={setHasChanged}
                                setTrialDetails={setTrialDetails}
                                setSiteIdManual={setSiteIdManual}
                                infoSettingChanged={infoSettingChanged}
                                setInfoSettingChanged={setInfoSettingChanged}
                                handleBasicInfoUpdate={handleBasicInfoUpdate}
                                ParticipantIdChanged={ParticipantIdChanged}
                                setParticipantIdChanged={setParticipantIdChanged}
                            />
                        }

                            <Investigator_SiteId_Accordion
                                trialDetails={trialDetails}
                                setTrialDetails={setTrialDetails}
                                setChangeSetting={setChangeSetting}
                                setHasChanged={setHasChanged}
                                allInvestigators={allInvestigators}
                                setAllInvestigators={setAllInvestigators}
                                onInvestigatorChange={onInvestigatorChange}
                                handleBasicInfoUpdate={handleBasicInfoUpdate}
                                setChangeTrialSetting={setChangeTrialSetting}
                                siteIdManual={siteIdManual}
                                investigatorChanged={investigatorChanged}
                                setInfoSettingChanged={setInfoSettingChanged}
                                setInvestigatorChanged={setInvestigatorChanged}
                                AllInvestigatorsData={AllInvestigatorsData}
                                isDrug={trialDetails.drug_assignment}
                            />

                            <TrialReviewersAccordion
                            role={role}
                            trialDetails={trialDetails}
                            allReviewers={allReviewers}
                            onReviewerChange={onReviewerChange}
                            handleBasicInfoUpdate={handleBasicInfoUpdate}
                            AllReviewerData={AllReviewerData}
                            setTrialDetails={setTrialDetails}
                            setAllReviewers={setAllReviewers}
                            setReviewerChanged={setReviewerChanged}
                            reviewerChanged={reviewerChanged}
                            errorMsg={errorMsg}
                            seterrorMsg={seterrorMsg}
                            />

                    </>
                }
                            {(trialDetails.drug_assignment && ((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') || (role === 'Super Admin'))) &&
                            <DrugAssignmentAccordion
                                trialDetails={trialDetails}
                                hasChanged={hasChanged}
                                setHasChanged={setHasChanged}
                                setTrialDetails={setTrialDetails}
                                handleBasicInfoUpdate={handleBasicInfoUpdate}
                                setDrugChanged={setDrugChanged}
                                DrugChanged={DrugChanged}
                            />
                        }
                            {(trialDetails.drug_assignment && ((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') || (role === 'Super Admin'))) &&
                            <DrugIdRangeAccordion
                                trialDetails={trialDetails}
                                hasChanged={hasChanged}
                                setHasChanged={setHasChanged}
                                setTrialDetails={setTrialDetails}
                                handleBasicInfoUpdate={handleBasicInfoUpdate}
                                DrugRangeChanged={DrugRangeChanged}
                                setDrugRangeChanged={setDrugRangeChanged}
                            />
                        }

                            {(trialDetails.is_maximum_photo_types && ((role === "CRO Admin") || (role === 'Cro_coordinator') || (role === 'Data Manager') || (role === 'Super Admin'))) &&
                            <MaximumPhotoTypesAccordion
                                trialDetails={trialDetails}
                                setTrialDetails={setTrialDetails}
                                handleBasicInfoUpdate={handleBasicInfoUpdate}
                                maximumPhototypes={maximumPhototypes}
                                setMaximumPhototypes={setMaximumPhototypes}
                            />
                        }
            </div>
        </>
    )
}


export default Basic_Info;