import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";

const AddNewPatientModal = (props) => {
  const [isManual, setIsManual] = useState(false);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [trialHasScreening, setTrialHasScreening] = useState(false);
  const [trailsList, setTrialsList] = useState([]);

  const user = useUser();
  const token = localStorage.getItem("token");
  const [emailError, setEmailError] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");

  const handleFirstName = (e) => {
    props.setPatientData({
      ...props.patientData,
      first_name: e.target.value,
    });
  };

  const handleMiddleName = (e) => {
    props.setPatientData({
      ...props.patientData,
      middle_name_initial: e.target.value,
    });
  };

  const handleLastName = (e) => {
    props.setPatientData({
      ...props.patientData,
      last_name: e.target.value,
    });
  };

  const handleBirthDate = (e) => {
    props.setPatientData({
      ...props.patientData,
      birth_date: e.target.value,
    });
  };

  const handlePhone = (e) => {
    props.setPatientData({
      ...props.patientData,
      phone: e.target.value,
    });
  };

  const handleTrialId = (e) => {
    props.setPatientData({
      ...props.patientData,
      trial_id: e.target.value,
    });
    const selectedOption = trailsList.find(
      (trial) => trial.id === e.target.value
    );
    const isManualValue = selectedOption?.is_manual || false;
    setIsManual(isManualValue);
    const drugValue = selectedOption?.manual_drug || false;
    props.setManualDrug(drugValue);
    setTrialHasScreening(selectedOption?.has_screening_visit || false);
  };

  const handleDrug = (e) => {
    props.setPatientData({
      ...props.patientData,
      drug: e.target.value,
    });
  };

  const handleParticipantId = (e) => {
    props.setPatientData({
      ...props.patientData,
      participant_number: e.target.value,
    });
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    return moment(dateString).format("YYYY-MM-DDTHH:mm");
  };

  const handleChangeDateTime = (e) => {
    const formattedDateTime = moment(e.target.value).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );
    props.changeDateTime(formattedDateTime);
  };

  const handlePatientEmail = (e) => {
    const newEmail = e.target.value;
    props.setPatientData({
      ...props.patientData,
      email: newEmail,
    });
  };

  const handleConfirmEmail = (e) => {
    const newConfirmEmail = e.target.value;
    props.setPatientData({
      ...props.patientData,
      confirm_email: newConfirmEmail,
    });

    setConfirmEmailError("");

    const timer = setTimeout(() => {
      if (newConfirmEmail !== props.patientData.email) {
        setConfirmEmailError("Emails do not match.");
      } else {
        setConfirmEmailError("");
      }
    }, 2000);
    
    return () => clearTimeout(timer);
  };

  const getTrialsList = () => {
    axios
      .get(`${__API_URL__}/api/photos/trials_list_view`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      });
  };

  useEffect(() => {
    getTrialsList();
  }, []);

  return (
    <Modal
      open
      onClose={() => props.modalShow(false)}
      style={{ backdropFilter: "blur(10px)" }}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <h2 id="contained-modal-title-vcenter">
            {user_data.role === "Investigator" ||
            user_data.role === "Inv_coordinator"
              ? "Add Subject"
              : "Add Patient"}
          </h2>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => props.modalShow(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            onChange={handlePatientEmail}
            value={props.patientData.email}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Enter your Email address"
            helperText={emailError || " "}
            error={!!emailError}
          />
          <TextField
            label="Confirm Email"
            type="email"
            fullWidth
            margin="normal"
            onChange={handleConfirmEmail}
            value={props.patientData.confirm_email}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Confirm your Email address"
            required
            helperText={confirmEmailError || " "}
            error={!!confirmEmailError}
          />
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            onChange={handleFirstName}
            value={props.patientData.first_name}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Enter your first name"
            required
          />
          <TextField
            label="Middle Name Initial [Only One Letter]"
            inputProps={{ maxLength: 1 }}
            fullWidth
            margin="normal"
            onChange={handleMiddleName}
            value={props.patientData.middle_name_initial}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Enter your middle initial"
            required
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            onChange={handleLastName}
            value={props.patientData.last_name}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Enter your last name"
            required
          />
          <TextField
            label="Birth Date"
            type="date"
            fullWidth
            margin="normal"
            onChange={handleBirthDate}
            value={props.patientData.birth_date}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Select your birth date"
            required
          />
          <TextField
            label="Phone No."
            fullWidth
            margin="normal"
            onChange={handlePhone}
            value={props.patientData.phone}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Enter your phone number"
            required
          />
          {props.patientTable && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="trial-select-label" shrink>
                Select Trial
              </InputLabel>
              <Select
                labelId="trial-select-label"
                value={props.patientData.trial_id || ""}
                label="Trial"
                onChange={handleTrialId}
              >
                {trailsList.map((trial) => (
                  <MenuItem
                    key={trial.id}
                    value={trial.id}
                    data-is-manual={trial.is_manual}
                    data-drug-manual={trial.manual_drug}
                    data-has-screening-visit={trial.has_screening_visit}
                  >
                    {trial.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {(props.is_manual || isManual || props.is_setting === false) && (
            <TextField
              label={
                user_data.role === "Investigator" ||
                user_data.role === "Inv_coordinator"
                  ? "Subject Id"
                  : "Participant Id"
              }
              fullWidth
              margin="normal"
              onChange={handleParticipantId}
              value={props.patientData.participant_number}
              autoComplete="off"
            />
          )}
          {(props.manual_drug || props.manualDrug) && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="drug-select-label" shrink>Drug</InputLabel>
              <Select
                labelId="drug-select-label"
                value={props.patientData.drug}
                label="Drug"
                onChange={handleDrug}
                inputProps={{ required: true }}
              >
                {props.drugKits.length > 0 ? (
                  props.drugKits.map((drug) => (
                    <MenuItem key={drug.value} value={drug.value}>
                      {drug.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    No options
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          <TextField
            label={
              (props.patientTable && trialHasScreening) ||
              props.trialHasScreening
                ? "Start Date - This is the Start Date of the Screening Visit"
                : "Start Date - This is the Start Date of the Baseline Visit"
            }
            type="datetime-local"
            fullWidth
            margin="normal"
            onChange={handleChangeDateTime}
            value={props.dateTime ? formatDateForInput(props.dateTime) : ""}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            onPaste={(e) => e.preventDefault()}
            placeholder="Select the start date and time"
            required
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              className="btn btn-primary btn-sm m-2"
              onClick={props.addPatientApi}
              disabled={emailError || confirmEmailError}
            >
              Add & Customize
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddNewPatientModal;
